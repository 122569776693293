<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-card>
            <v-toolbar dark color="blue" class="darken-1">
                <v-toolbar-title>{{$t('upload_immigration_from_log')}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-snackbar v-model="snackbar.snackbar"
                        color="error"
                        :timeout="callbackResponse.timeout"
                        top>
                {{ callbackResponse.message }}
            </v-snackbar>
            <v-card-text>
                <v-container>
                    <form class="fix-for-nepali-label">
                        <v-row>
                            <v-col class="d-flex" cols="12" sm="6">
                                <v-menu v-model="snackbar.snackbar.menu1"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        :close-on-content-click="false"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="date"
                                                      clearable
                                                      dense
                                                      outlined
                                                      prepend-inner-icon="event"
                                                      readonly
                                                      :label="$t('select_date')"
                                                      v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="date">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-btn class="mt-3" dark color="blue darken-1" @click="submit">{{ $t("import") }}</v-btn>

                        </v-row>
                        
                    </form>
                </v-container>
            </v-card-text>
            <template slot="loadingContent">
                <v-progress-circular :size="50"
                                     color="primary"
                                     indeterminate></v-progress-circular>

            </template>
            <v-card-actions>


            </v-card-actions>
        </v-card>
    </v-content>

</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createHouseForm",
        props: ["success"],

        computed: {
            imageError() {
                const errors = [];
                if (!this.$v.editedData.excelFile.$dirty) return errors;
                !this.$v.editedData.excelFile.required && errors.push("Image is required.");
                return errors;
            },
            items() {
                return [
                    {
                        text: this.$t('import_immigration_raw_data'),
                        exact: true,
                        to: '/ImmigrationRawDataImport'
                    },
                    {
                        text: this.$t('import_immigration_raw_log_data'),
                        disabled: true,
                        exact: true
                    }
                ]
            },
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                },
                returnData: [],
                houseType: [],
                roomType: [],
                show: false,
                updated: false,
                date: "",
                snackbar: {
                    snackbar: false,
                    isLoading: false,
                },
                editedData: {
                    excelFile: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: "",
                },
            };
        },
        validations: {
            editedData: {
                excelFile: { required },
            },
        },
        destroyed() { },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData);
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                //axios.get('Hotel/RoomList').then((response) => {
                //    this.roomType = response.data;
                //})
            },
            submit() {
                this.snackbar.isLoading = true;
                axios.get('Immigration/ImmigrationRawDataLogImport/' + this.date).then(response => {
                        this.divisions = response.data
                })
                this.snackbar.isLoading = false;
            },
            clear() {
                this.$v.$reset();
                this.editedData.roomCategoryID = "";
            },
        },
    };
</script>

<style scoped></style>
